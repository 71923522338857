import React from 'react';

import ConfirmResetModal from './ConfirmResetModal';

import { Button, List, Image, Icon, Popup } from 'semantic-ui-react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { isMobile } from 'react-device-detect';

class DeviceListItem extends React.Component {

  constructor(props) {
    super(props);

    this.onClickTest = this.onClickTest.bind(this);
    this.onClickReset = this.onClickReset.bind(this);

    this.onClickPairingMode = this.onClickPairingMode.bind(this);
    this.onConfirmedReset = this.onConfirmedReset.bind(this);
    this.onAbortedReset = this.onAbortedReset.bind(this);

    this.state = {
      showConfirmModal: false,
      showPairingModeTimer: false,
      deviceIndexToReset: null
    };

  }

  onClickTest() {

    // Gets the index of clicked device
    var index = parseInt(this.props.index);
    console.log(`The user ask to test the device with index: '${index}'`);

    // Tests the device with the provided index
    this.props.testDevice(index);
  }

  onClickPairingMode() {

    // Gets the index of clicked device
    var index = parseInt(this.props.index);

    this.setState({ showPairingModeTimer: true });

    console.log(`Asked to enable the pairing mode of the device with index: '${index}'!`);

    // Enable the paring mode of the device
    this.props.enablePairingMode(index);
  }

  onEndPairingMode() {
    console.log('Pairing mode was automatically deactivated after 120 sec.');

    this.setState({ showPairingModeTimer: false });
  }

  onClickReset() {

    // Gets the index of clicked device
    var index = parseInt(this.props.index);
    console.log(`The user ask to reset the device with index: '${index}'`);

    // Saves the index of clicked device and show the modal
    this.setState({ deviceIndexToReset: index, showConfirmModal: true });
  }

  onConfirmedReset() {
    console.log(`Confirmed the reset of the device with index: '${this.state.deviceIndexToReset}'!`);

    // Removes the device
    this.props.removeDevice(this.state.deviceIndexToReset);

    // Clear the state
    this.setState({ showConfirmModal: false, deviceIndexToReset: null })
  }

  onAbortedReset() {
    console.log(`Aborted the reset of the device with index: '${this.state.deviceIndexToReset}'!`);

    // Clear the state
    this.setState({ showConfirmModal: false, deviceIndexToReset: null })
  }

  render() {
    return([
      <ConfirmResetModal
        icon='close'
        title='Are you sure you want to reset the device?'
        opem={this.state.showConfirmModal}
        content='This operation is not recoverable and you will lost all the configuration, your device will be bring back to factory settings.'
        onPositiveClick={ () => { this.onConfirmedReset() } }
        onNegativeClick={ () => { this.onAbortedReset() } }
      />
      ,

      <List.Item
        key={this.props.index}>

        <List.Content
          floated='right'>

          { (this.state.showPairingModeTimer) ?
            (
              <div style={{ float: "left", fontSize: 10, marginRight: 5 }}>
                <CountdownCircleTimer
                  onComplete={ () => this.onEndPairingMode() }
                  isPlaying
                  duration={1200}
                  colors="#186b89"
                  size={35}
                  strokeWidth={2}
                >
                 { ({ remainingTime }) =>
                  {
                    const minutes = Math.floor(remainingTime / 60)
                    const seconds = remainingTime % 60

                    if (minutes > 0) {
                      return `${minutes} min`
                    } else {
                      return `${seconds} sec`
                    }
                  }
                 }
                </CountdownCircleTimer>
              </div>
            ) : (
              <Popup
                disabled={ (isMobile) ? true : false }
                trigger={
                  <Icon
                    className='testIcon'
                    name="mobile"
                    onClick={ () => this.onClickPairingMode() }
                  />
                }
                content="Enable pairing mode for the association of a new remote controller"
                on='hover'
                basic />
            )

          }
          <Popup
            disabled={ (isMobile) ? true : false }
            trigger={
              <Icon
                className='testIcon'
                name="power"
                onClick={ () => this.onClickTest() }
              />
            }
            content="Test turning on/off hood's light"
            on='hover'
            basic />

          <Popup
            disabled={ (isMobile) ? true : false }
            trigger={
              <Button
                negative
                onClick={ () => this.onClickReset() }>
                RESET
              </Button>}
            content="Reset the device to the factory settings"
            on='hover'
            basic />
        </List.Content>

        <Image
          avatar
          className='notSoTiny'
          src={window.location.origin + '/hood.png'} />

        <List.Content>
          <List.Header className="primaryText">
            {this.props.device.id}
          </List.Header>
          <List.Description>
            <b>Firmware version:</b> {this.props.device.firmwareVersion}
          </List.Description>
          <List.Description>
            <b>Hardware version:</b> {this.props.device.hardwareVersion}
          </List.Description>

        </List.Content>

      </List.Item>

    ]);
  }
}

export default DeviceListItem;