import React from 'react';

import DeviceListItem from './DeviceListItem';

import { List, Header, Icon } from 'semantic-ui-react'

class DeviceList extends React.Component {

  render() {

    // If there's no items, give empty array
    const devices = this.props.devices || []

    // For each device in the list create a DeviceListItem
    var devicesItems = devices.map((device, index) => {
      return (
        <DeviceListItem
          device={device}
          index={index}
          testDevice={this.props.testDevice}
          removeDevice={this.props.removeDevice}
          enablePairingMode={this.props.enablePairingMode} />
      );
    });

    // Then returns as list
    return (
    <div className={ devicesItems.length === 0 ? "emptyList" : null }>

        { ( devicesItems.length === 0 ) ? (

          <Header
            as='h2'
            icon>

            <Icon
              className='emptyList'
              name='settings' />

            <p className='emptyList'>
              No devices found
            </p>

            <Header.Subheader>
              Add your devices filling the form!
            </Header.Subheader>

          </Header>
        ) : (

          <List
            divided
            relaxed
            verticalAlign='middle'>

            {devicesItems}

          </List>

        ) }

    </div>
   );

  }
}

export default DeviceList;