import React from 'react';

import { AmplifyFormField, AmplifyButton } from '@aws-amplify/ui-react';
import { Header } from 'semantic-ui-react'

class AddDeviceForm extends React.Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    this.state = {
      newDeviceId: this.props.initialDeviceId,
      isSubmitButtonEnabled: ( this.props.initialDeviceId ? true : false)
    };
  }

  onChange(event) {

    console.log(`'${event.target.value}'`);

    if (!event.target.value) {
      console.log("Clearing form...");
      this.setState({ newDeviceId: '', isSubmitButtonEnabled: false });
      return;
    }

    // Enable the submit
    this.setState({ newDeviceId: event.target.value, isSubmitButtonEnabled: true });
  }

  onSubmit(event) {

    // If some text is available
    if (this.state.newDeviceId) {

      // Adds the device
      this.props.addDevice({ id: this.state.newDeviceId });

      // Resets the form
      this.setState({ newDeviceId: '', isSubmitButtonEnabled: false });
    }
  }

  onKeyPress(event) {
    // Handle only enter key
    if (event.charCode !== 13) {
      return;
    }
    // Proceeds as submit
    this.onSubmit();
  }

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        onKeyPress={this.onKeyPress}>

        <Header
          className='white'
          size='medium'>
          Add a new device
        </Header>

        <AmplifyFormField
          className="invertedColor"
          placeholder="Device's ID identifier"
          description="Set here the ID of the device that your want to add to your devices"
          type="text"
          value={this.state.newDeviceId}
          handleInputChange={this.onChange}/>


        <AmplifyButton
          disabled={!this.state.isSubmitButtonEnabled}
          type="submit"
          handleButtonClick={this.onSubmit}>
          ADD
        </AmplifyButton>

      </form>
    );
  }
}

export default AddDeviceForm;