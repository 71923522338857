import React from 'react';

import DeviceListHeader from './DeviceListHeader';
import DeviceList from './DeviceList';
import AddDeviceForm from './AddDeviceForm';

import { API } from 'aws-amplify';
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';
import { Segment, Dimmer } from 'semantic-ui-react'

const API_NAME = 'kDesignUsersDevicesApi';


function wait(delayMillis) {
  return new Promise( res => setTimeout(res, delayMillis) );
}

class KDesignApp extends React.Component {

  constructor(props) {
    super(props);

    this.fetchDevices = this.fetchDevices.bind(this);
    this.linkDevice = this.linkDevice.bind(this);
    this.resetDevice = this.resetDevice.bind(this);
    this.pingDevice = this.pingDevice.bind(this);
    this.setPairingMode = this.setPairingMode.bind(this);

    this.addDevice = this.addDevice.bind(this);
    this.removeDevice = this.removeDevice.bind(this);
    this.refreshDevices = this.refreshDevices.bind(this);
    this.testDevice = this.testDevice.bind(this);
    this.enablePairingMode = this.enablePairingMode.bind(this);


    // Gets the query parameters
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Gets the ID (if provided)
    const initialDeviceId = urlParams.get('id')
    console.log(`The initial ID provided by the URL is: '${initialDeviceId}'`);

    this.state = {
      isLoading: false,
      devices: [],
      initialDeviceId: initialDeviceId
    };
  }

  componentDidMount() {
    // Refresh the list of devices
    this.refreshDevices();
  }

  async fetchDevicesAsync(forceRefresh) {

    const path = '/devices';

    try {

        const response = await API.get(API_NAME, path, (forceRefresh) ? ( { headers: { 'Cache-Control': 'no-cache' } } ) : ( null ));
        console.log(response)

        this.setState({ devices: response.devices });
    } catch (e) {
      if (e.response) {
        var message = e.response.data.message;
      } else {
        console.log(e);
        message = "Something went wrong..."
      }
      console.error(message);

      this.props.showAlertMessage({ class: "ERROR", message: message });
    }
  }

  fetchDevices = this.fetchDevicesAsync;

  async refreshDevices() {

    this.setState({ isLoading: true });

    await this.fetchDevices(false);

    this.setState({ isLoading: false });
  }

  async linkDeviceAsync(device) {

    const id = device.id;
    const path = `/devices/${id}`;

    try {

        await API.put(API_NAME, path, null);

        this.props.showAlertMessage({ class: "ERROR", message: `Device with ID: '${id}' added with success!` });
    } catch (e) {
      if (e.response) {
        var message = e.response.data.message;
      } else {
        console.log(e);
        message = "Something went wrong..."
      }
      console.error(message);

      this.props.showAlertMessage({ class: "ERROR", message: message });
    }
  }

  linkDevice = this.linkDeviceAsync;

  async pingDeviceAsync(device) {

    const id = device.id;
    const path = `/devices/${id}/test`;

    try {

        const response = await API.get(API_NAME, path, null);
        console.log(response)

    } catch (e) {
      if (e.response) {
        var message = e.response.data.message;
      } else {
        console.log(e);
        message = "Something went wrong..."
      }
      console.error(message);

      this.props.showAlertMessage({ class: "ERROR", message: message });
    }
  }

  pingDevice = this.pingDeviceAsync;

  async setPairingModeAsync(device, toEnable) {

    const id = device.id;
    const path = toEnable ? `/devices/${id}/enablePairingMode` : `/devices/${id}/disablePairingMode`;

    try {

        const response = await API.get(API_NAME, path, null);
        console.log(response)

    } catch (e) {
      if (e.response) {
        var message = e.response.data.message;
      } else {
        console.log(e);
        message = "Something went wrong..."
      }
      console.error(message);

      this.props.showAlertMessage({ class: "ERROR", message: message });
    }
  }

  setPairingMode = this.setPairingModeAsync;

  async addDevice(device) {
    await this.setState({ isLoading: true });

    // Links the new device to the current user
    await this.linkDevice(device);

    // Waits for 1 sec
    await wait(1000);

    // Refreshes the list of the devices
    await this.fetchDevices(true);

    // Removes all the params provided inside the URL (eg. id for easy device addition)
    var url= document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);

    await this.setState({ isLoading: false });
  }

  async resetDeviceAsync(device) {

    const id = device.id;
    const path = `/devices/${id}`;

    try {

        await API.del(API_NAME, path, null);

        this.props.showAlertMessage({ class: "ERROR", message: `Device with ID: '${id}' removed with success!` });
    } catch (e) {
      if (e.response) {
        var message = e.response.data.message;
      } else {
        console.log(e);
        message = "Something went wrong..."
      }
      console.error(message);

      this.props.showAlertMessage({ class: "ERROR", message: message });
    }
  }

  resetDevice = this.resetDeviceAsync;

  async removeDevice(index) {

    await this.setState({ isLoading: true });

    var devices = this.state.devices.slice();
    const device = devices[index];

    // Resets the device provided
    await this.resetDevice(device)

    // Waits for 1 sec
    await wait(1000);

    // Refreshes the list of the devices
    await this.fetchDevices(true);

    await this.setState({ isLoading: false });
  }

  async testDevice(index) {

    await this.setState({ isLoading: true });

    var devices = this.state.devices.slice();
    const device = devices[index];

    // Resets the device provided
    await this.pingDevice(device)

    await this.setState({ isLoading: false });
  }

  async enablePairingMode(index) {

    var devices = this.state.devices.slice();
    const device = devices[index];

    // Resets the device provided
    await this.setPairingMode(device, true)
  }

  render() {
    return (
      <div>

        <div class="text-wrapper">
          <p>
          Benvenuto nella pagina di configurazione della tua nuova cappa a controllo vocale!
          </p>
          <p>
          Nella casella in basso inserisci il codice ID che ti abbiamo indicato in precedenza e premi su ADD.
          La tua cappa adesso è configurata, premi il pulsante di test qui in basso per assicurarti che la procedura sia andata a buon fine.
          A questo punto ti basta aprire l'app di Alexa per completare il processo di configurazione.
          </p>
        </div>

        <DeviceListHeader
          isLoading={this.state.isLoading}
          refresh={this.refreshDevices} />

        <Segment
          style={{ borderRadius: 6 }}>

            <Dimmer
              inverted
              active = {this.state.isLoading} >

              <AmplifyLoadingSpinner />

            </Dimmer>

            <DeviceList
              devices={this.state.devices}
              testDevice={this.testDevice}
              removeDevice={this.removeDevice}
              enablePairingMode={this.enablePairingMode} />

        </Segment>

        <AddDeviceForm
          initialDeviceId={this.state.initialDeviceId}
          addDevice={this.addDevice} />

      </div>

    );
  }
}

export default KDesignApp;