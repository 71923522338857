/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ca6bfc32-882d-462e-af12-a36399625634",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_HyLnQ3NAp",
    "aws_user_pools_web_client_id": "19j9l90n699uld2merarq05r9k",
    "oauth": {
        "domain": "kdesign-new.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://new.d35r1ytvyp0z0x.amplifyapp.com/,https://registra.cappacomandovocale.it/",
        "redirectSignOut": "http://localhost:3000/,https://new.d35r1ytvyp0z0x.amplifyapp.com/,https://registra.cappacomandovocale.it/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "kDesignUsersDevicesApi",
            "endpoint": "https://ii8lqcqq2l.execute-api.eu-west-1.amazonaws.com/new",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "kdesignwebapp-20210412142912-hostingbucket-new",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://kdesignwebapp-20210412142912-hostingbucket-new.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
