import React from 'react'

import { Button, Modal, Header, Icon } from 'semantic-ui-react';

class ConfirmModal extends React.Component {

  render() {
    return(
        <Modal
          basic
          dimmer='blurring'
          open={this.props.opem}
          size='small'
        >

          <Header icon>
            <Icon name={this.props.icon} />
            {this.props.title}
          </Header>

          <Modal.Content>
            <p>
              {this.props.content}
            </p>
          </Modal.Content>

          <Modal.Actions>

            <Button basic color='red' inverted onClick={this.props.onNegativeClick}>
              <Icon name='remove' /> No
            </Button>

            <Button basic color='green' inverted onClick={this.props.onPositiveClick}>
              <Icon name='checkmark' /> Yes
            </Button>

          </Modal.Actions>

        </Modal>
    )
  }
}

export default ConfirmModal