import './App.css';

import React, { useState, useEffect } from 'react';

import { Auth, Hub } from 'aws-amplify';
import { AmplifyFormField, AmplifyButton, AmplifyHint, AmplifyToast } from '@aws-amplify/ui-react';

import { Image, Container, Grid } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import KDesignApp from './components/KDesignApp';
import GoogleSignUpButton from './components/GoogleSignUpButton'
import AppleSignUpButton from './components/AppleSignUpButton'


function App() {
  const [ui, setUi] = useState("signIn");

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [code, setCode] = useState(null);

  const [alert, setAlert] = useState(null);

  useEffect(() => {

    Hub.listen('auth', ({ payload: { event, data } }) => {
      // Clears the Alert at each Auth event
      setAlert(null);

      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('signIn/cognitoHostedUI', data);

          getUser().then(userData => setUser(userData));

          // Clears the state
          setEmail(null);
          setPassword(null);
          setCode(null);

          break;
        case 'signOut':

          // Clears the state
          setUser(null);

          // Shows the Sign In form
          setUi("signIn")

          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);

          // User not found
          if (data.code === "UserNotFoundException") {
            setAlert({ class: "ERROR", message: "User does not exist" });
            break;
          }

          // Wrong email or password
          if (data.code === "NotAuthorizedException") {
            setAlert({ class: "ERROR", message: "Incorrect username or password" });
            break;
          }

          // User not confirmed
          if (data.code === "UserNotConfirmedException") {
            setAlert({ class: "ERROR", message: "User is not confirmed" });
            break;
          }

          // Generic error
          setAlert({ class: "ERROR", message: "Something went wrong, please try again" });

          break;
        case 'signUp_failure':
          console.log("Sign up failure", data);

          // User already exists
          if (data.code === "UsernameExistsException") {
            setAlert({ class: "ERROR", message: "An account with the given email already exists" });
            break;
          }

          // Invalid password provided
          if ((data.code === "InvalidParameterException" && data.message.includes('password')) || (data.code === "InvalidPasswordException")) {
            setAlert({ class: "ERROR", message: "Invalid password format, please provide a password containing at least 8 characters" });
            break;
          }

          // Invalid email provided
          if (data.code === "InvalidParameterException" && data.message.includes('email')) {
            setAlert({ class: "ERROR", message: "Invalid email address format" });
            break;
          }

          // Generic error
          setAlert({ class: "ERROR", message: "Something went wrong, please try again" });

          break;
        case 'signUp':
          console.log("Sign Up", data);

          // Shows the confirm Sign Up form with code verificaiton
          setUi("confirmSignUp");
          break;
        case 'forgotPassword':
          console.log("forgotPassword", data);

          // Change the UI allowing the user to add the new password and the received code
          setUi('forgotPasswordSubmit');
          break;
        case 'forgotPasswordSubmit':
          console.log("forgotPasswordSubmit", data);

          break;
        case 'forgotPassword_failure':
          console.log("forgotPassword_failure", data);

          // User not found
          if (data.code === "UserNotFoundException") {
            setAlert({ class: "ERROR", message: "User does not exist" });
            break;
          }

          // Generic error
          setAlert({ class: "ERROR", message: "Something went wrong, please try again" });

          break;
        case 'forgotPasswordSubmit_failure':
          console.log("forgotPasswordSubmit_failure", data);

          // Wrong password provided
          if ((data.code === "InvalidParameterException" && data.message.includes('password')) || (data.code === "InvalidPasswordException")) {
            setAlert({ class: "ERROR", message: "Invalid password format, please provide a password containing at least 8 characters" });
            break;
          }

          // Wrong code provided
          if (data.code === "CodeMismatchException") {
            setAlert({ class: "ERROR", message: "Invalid verification code provided, please try again" });
            break;
          }

          // Generic error
          setAlert({ class: "ERROR", message: "Something went wrong, please try again" });

          break;
        case 'customState_failure':
          console.log("customState_failure", data);

          break;
        default:
          console.log('Not managed event:', event);
          break;
      }
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  function signIn() {
    console.log(`signIn with email: '${email}' and password: '${password}'`)

    Auth.signIn(email, password)
      .catch((error) => console.log(error));
  }

  function signUp() {
    console.log(`signUp with email: '${email}' and password: '${password}'`)

    Auth.signUp({username: email, password: password, attributes: { email }}).catch((error) => console.log(error));
  }

  function forgotPassword() {
    console.log(`forgotPassword with email: '${email}'`)

    Auth.forgotPassword(email)
        .catch((error) => console.log(error));
  }

  function confirmSignUp() {
    console.log(`confirmSignUp with email: '${email}' and '${code}'`)

    Auth.confirmSignUp(email, code)
        .then(() => signIn(email, password))
        .catch((error) => {
          if (error.code === "CodeMismatchException") {
            setAlert({ class: "ERROR", message: "Invalid verification code provided, please try again" });
          } else {
            setAlert({ class: "ERROR", message: "Something went wrong during code verification, please try again" })
          }

        });
  }

  function resendSignUp() {
    console.log(`resendSignUp with email: '${email}'`)

    Auth.resendSignUp(email)
      .catch((error) => console.log(error))
  }

  function forgotPasswordSubmit() {
    console.log(`forgotPasswordSubmit with email: '${email}', password: '${password}' and code: '${code}'`)

    Auth.forgotPasswordSubmit(email, code, password)
        .then(() => setUi('signIn'))
        .catch((error) => console.log(error))
  }

  function showAlertMessage(alert) {
    console.log(`showAlertMessage with message: '${alert.message}`)

    setAlert(alert);
  }

  return (
    <div className="app">

      { (alert && alert.class && alert.message) ? (

        <AmplifyToast
          message={alert.message}
          handleClose={() => { setAlert(null) } } />

      ) : (
        null
      ) }

      <Container style={{ marginTop: 32, marginBottom: 32 }}>
        <Image centered src={window.location.origin + '/logo192.png'} />

        { user ? (

          <div>
            <KDesignApp
              showAlertMessage={showAlertMessage} />
            <AmplifyButton
              type="submit"
              handleButtonClick={() => Auth.signOut()}>
              Sign Out
            </AmplifyButton>
          </div>

          ) : (
          <Grid className="signin-signup-form" stackable centered container columns={ (ui === 'signIn') ? 2 : 1 } divided={ (ui === 'signIn') ? true : false }>
            <Grid.Row>

              { (ui === 'signIn') ?
                (
                  <Grid.Column className="left">

                    <div>
                      <h3 className="header">Sign In with your social account</h3>
                      <AppleSignUpButton onClick={() => Auth.federatedSignIn({provider: 'SignInWithApple'})} />
                      <GoogleSignUpButton onClick={() => Auth.federatedSignIn({provider: 'Google'})} />
                      <AmplifyHint>
                        We won't post to any accounts without asking first
                      </AmplifyHint>
                    </div>

                  </Grid.Column>
                ) : (
                  ''
                )
              }

              <Grid.Column className={ (ui === 'signIn') ? "right" : '' }>
                {
                  {
                    'signIn':
                      <form>
                        <h3 className="header">Sign in with your email and password</h3>

                        <AmplifyFormField
                          disabled="false"
                          placeholder="name@host.com"
                          description="Email address *"
                          type="email"
                          handleInputChange={(event) => {setEmail(event.target.value.trim())}}
                          value={email}/>

                        <AmplifyFormField
                          placeholder="Password"
                          description="Password *"
                          type="password"
                          handleInputChange={(event) => {setPassword(event.target.value.trim())}}
                          value={password}/>
                        <AmplifyHint>
                          Forgot your password? <span className="clickable" onClick={() => setUi("forgotPassword")}>Reset password</span>
                        </AmplifyHint>

                        <AmplifyButton
                          disabled={!email || !password}
                          type="submit"
                          handleButtonClick={() => signIn()}>
                          Sign In
                        </AmplifyButton>

                        <AmplifyHint>
                          Need an account? <span className="clickable" onClick={() => setUi("signUp")}>Sign up</span>
                        </AmplifyHint>

                      </form>,
                    'signUp':
                      <form>
                        <h3 className="header">Sign up with your email and password</h3>

                        <AmplifyFormField
                          disabled="false"
                          placeholder="name@host.com"
                          description="Email address *"
                          type="email"
                          handleInputChange={(event) => {setEmail(event.target.value.trim())}}
                          value={email}/>

                        <AmplifyFormField
                          placeholder="Password"
                          description="Password *"
                          type="password"
                          hint=""
                          handleInputChange={(event) => {setPassword(event.target.value.trim())}}/>

                        <AmplifyButton
                          disabled={!email || !password}
                          type="submit"
                          handleButtonClick={() => signUp()}>
                          Sign Up
                        </AmplifyButton>

                        <AmplifyHint>
                          Already have an account? <span className="clickable" onClick={() => setUi("signIn")}>Sign in</span>
                        </AmplifyHint>

                      </form>,
                    'forgotPassword':
                      <form>
                        <h3 className="header">Reset your password</h3>

                        <AmplifyFormField
                          disabled="false"
                          placeholder="name@host.com"
                          description="Email address *"
                          type="email"
                          handleInputChange={(event) => {setEmail(event.target.value.trim())}}
                          value={email}/>

                        <AmplifyButton
                          disabled={!email}
                          type="submit"
                          handleButtonClick={() => forgotPassword()}>
                          Send Code
                        </AmplifyButton>

                        <AmplifyHint>
                          Back to <span className="clickable" onClick={() => setUi("signIn")}>Sign in</span>
                        </AmplifyHint>

                      </form>,
                    'forgotPasswordSubmit':
                      <form>
                        <h3 className="header">Reset your password</h3>

                        <AmplifyFormField
                          placeholder="Enter your code"
                          description="Verification code *"
                          type="number"
                          handleInputChange={(event) => {setCode(event.target.value.trim())}}
                          value={code}/>

                        <AmplifyFormField
                          placeholder="Enter your new password"
                          description="New password *"
                          type="password"
                          hint=""
                          handleInputChange={(event) => {setPassword(event.target.value.trim())}}/>

                        <AmplifyButton
                          disabled={!code || !password}
                          type="submit"
                          handleButtonClick={() => forgotPasswordSubmit()}>
                          Submit
                        </AmplifyButton>

                        <AmplifyHint>
                          Back to <span className="clickable" onClick={() => setUi("signIn")}>Sign in</span>
                        </AmplifyHint>

                      </form>,
                    'confirmSignUp':
                      <form>
                        <h3 className="header">Confirm Sign up</h3>

                        <AmplifyFormField
                          disabled
                          placeholder="name@host.com"
                          description="Email address *"
                          type="email"
                          handleInputChange={(event) => {setEmail(event.target.value.trim())}}
                          value={email}/>

                        <AmplifyFormField
                          placeholder="Enter your code"
                          description="Confirmation code *"
                          type="number"
                          handleInputChange={(event) => {setCode(event.target.value.trim())}}
                          value={code}/>
                        <AmplifyHint>
                          Lost your code? <span className="clickable" onClick={() => resendSignUp()}>Resend code</span>
                        </AmplifyHint>

                        <AmplifyButton
                          disabled={!code || !password}
                          type="submit"
                          handleButtonClick={() => confirmSignUp()}>
                          Confirm
                        </AmplifyButton>

                        <AmplifyHint>
                           Back to <span className="clickable" onClick={() => setUi("signIn")}>Sign in</span>
                        </AmplifyHint>

                      </form>,
                  }[ui]
                }

              </Grid.Column>

            </Grid.Row>
          </Grid>
          )
        }

      </Container>

    </div>
  );
}

export default App;